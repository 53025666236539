import { request } from '@/utils/axios'

// 产销对接
// 概况
export const getBaseInfo = () => {
    return request({
        method: 'get',
        url: `${sysUrl}/web/dockingOverview/list?orderNum=year&pageSize=1&isAsc=desc`
    })
}



// // 成交
// export const getDealRecord = (params, headers) => {
//     return request({
//         method: 'get',
//         url: `${productApi}/api/serveTotal/dealRecord`,
//         headers,
//         params
//     })
// }

// // 每天供应量
// export const getSupplyDayNum = (params, headers) => {
//     return request({
//         method: 'get',
//         url: `${productApi}/api/serveTotal/supplyDayNum`,
//         headers,
//         params
//     })
// }

// // 每天采购量
// export const getBuyDayNum = (params, headers) => {
//     return request({
//         method: 'get',
//         url: `${productApi}/api/serveTotal/buyDayNum`,
//         headers,
//         params
//     })
// }

// // 每天成交量
// export const getDealRecordDayNum = (params, headers) => {
//     return request({
//         method: 'get',
//         url: `${productApi}/api/serveTotal/dealRecordDayNum`,
//         headers,
//         params
//     })
// }


// 概列表况
export const getTableList = (params, headers) => {
    return request({
        method: 'get',
        url: `${cxdjcs}/business/productionOrder/list`,
        headers,
        params
    })
}
// 展销活动
export const getActivityList = (params, headers) => {
    return request({
        method: 'get',
        url: `${cxdjcs}/web/recommend/passTokenList`,
        headers,
        params
    })
}