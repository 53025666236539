<template>
    <div class="cxdj">
        <div class="top">
            <div class="info-item" v-for="item in info" :key="item.name">
                <span>{{ item.name }}</span>
                <div class="text-wrapper" v-for="num in item.count" :key="num">
                    <div class="num">{{ num }}</div>
                </div>
                <span>吨</span>
            </div>
        </div>
        <div class="bottom">
            <div class="left pub_border">
                <div class="pub_title">展销活动</div>
                <ul class="list" v-infinite-scroll="activeLoad" :infinite-scroll-distance="1"
                    infinite-scroll-disabled="disabled">
                    <li v-for="(item, index) in activityList" :key="index" @click="handleClick(item)">
                        <img :src="cxdjcs + item.img" alt="图片资源不存在">
                        <div v-html="item.content"></div>
                    </li>
                    <p v-if="loading">加载中...</p>
                    <p v-if="noMore">没有更多了</p>
                </ul>
            </div>
            <div class="right">
                <div class="operator">
                    <el-input class="pub_input" v-model="listQuery.productName" placeholder="品种"></el-input>
                    <el-input class="pub_input" v-model="listQuery.providersAddress" placeholder="区域"></el-input>
                    <el-date-picker class="pub_input" v-model="listQuery.creatTime" type="date" placeholder="选择日期"
                                    value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <div class="btn" @click="tableSearch"><i class="el-icon-search"></i></div>
                </div>
                <el-table ref="table" :data="tableData" stripe class="pub_table" height="630px" @row-click="selectRow"
                          :cell-style="cellStyle">
                    <el-table-column prop="productName" label="品种" align="center"></el-table-column>
                    <el-table-column prop="unit" label="规格" align="center" width="70"></el-table-column>
                    <el-table-column prop="unitPrice" label="单价(元)" align="center"></el-table-column>
                    <el-table-column prop="num" label="数量" align="center"></el-table-column>
                    <!-- <el-table-column prop="providersCompanyName" label="供应商" align="center" width="300"></el-table-column> -->
                    <el-table-column prop="creatTime" label="发布时间" align="center"></el-table-column>
                    <el-table-column prop="purchaserCompanyName" label="采购商" align="center" width="320"></el-table-column>
                </el-table>
                <el-pagination class="pub_laypage" :page-size="listQuery.pageSize" @current-change="getTableList"
                               :current-page.sync="listQuery.pageNum" prev-text="上一页" next-text="下一页" :pager-count="11"
                               layout="prev, pager, next,total" :total="total">
                </el-pagination>
            </div>
        </div>
        <base-dialog width="1000px" height="500px" label="数据详情" v-show="dialogFlag" :show-close="true"
                     @closeDialog="closeDialog">
            <ul class="itemDetail">
                <li>供应商：{{ this.itemDetail.providersCompanyName || '' }}</li>
                <li>品种：{{ this.itemDetail.productName || '' }}</li>
                <li>规格：斤</li>
                <li>单价（元）：{{ this.itemDetail.unitPrice || '' }}</li>
                <li>数量：{{ this.itemDetail.num || '' }}</li>
                <li>发布时间：{{ this.itemDetail.creatTime || '' }}</li>
                <li>采购商：{{ this.itemDetail.purchaserCompanyName || '' }}</li>
            </ul>
        </base-dialog>
        <article-detail ref="articleRef"></article-detail>
    </div>
</template>

<script>
    import { getBaseInfo, getTableList, getActivityList } from '@/api/cxdj'
    import { ERR_OK } from '@/utils/config'
    import BaseDialog from '@/components/dialog/baseDialog' //品种弹框
    import ArticleDetail from '@/components/articleDetail' //图文详情
    export default {
        name: 'Cxdj',
        components: { BaseDialog, ArticleDetail },
        data() {
            return {
                info: [{
                    name: '供应量',
                    count: ''
                }, {
                    name: '采购量',
                    count: ''
                }, {
                    name: '成交量',
                    count: ''
                }],
                listQuery: {
                    productName: '',
                    providersAddress: '',
                    creatTime: '',
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0,
                tableData: [],
                dialogFlag: false,
                itemDetail: {},
                activityQuery: {
                    pageNum: 1,
                    pageSize: 4,
                    type: 3
                },
                activityList: [],
                loading: false,
                activityListTotal: 0,
                cxdjcs,
            }
        },
        mounted() {
            this.getBaseInfo()
            this.getTableList()
            this.getActivityList()
        },
        computed: {
            noMore() {
                // 当起始页数大于等于总页数时停止加载
                return this.activityList.length >= this.activityListTotal;
            },
            disabled() {
                return this.loading || this.noMore
            },
        },
        methods: {
            // 概况
            getBaseInfo() {
                return getBaseInfo().then(res => {
                    if (res.code === ERR_OK) {
                        let data = res.rows[0]
                        // 供应
                        this.info[0].count = data.supply
                        // 采购
                        this.info[1].count = data.purchase
                        // 成交
                        this.info[2].count = data.deal
                    }
                })
            },
            tableSearch() {
                this.listQuery.page = 1
                this.getTableList()
            },
            // 采购、供应
            getTableList() {
                getTableList({
                    ...this.listQuery
                }, {
                    autp: 2,
                    token: window.sessionStorage.token,
                    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }).then(res => {
                    if (res.code === ERR_OK) {
                        this.tableData = res.rows
                        this.tableData.forEach(item => {
                            item.unit = '斤';
                        })
                        this.total = res.total;
                    }
                })
            },
            selectRow(row) {
                this.itemDetail = row;
                this.dialogFlag = true;
            },
            closeDialog() {
                this.dialogFlag = false;
            },
            getActivityList() {
                getActivityList({
                    ...this.activityQuery
                }, {
                    autp: 2,
                    token: window.sessionStorage.token,
                    Authorization: 'Bearer ' + window.sessionStorage.token,
                    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }).then(res => {
                    // console.log(res.data, 'llllllllllllllllll');
                    if (res.code === ERR_OK) {
                        this.activityList = [...this.activityList, ...res.rows];
                        this.activityListTotal = res.total;
                        this.loading = false;
                    }
                }).catch(() => {
                    this.loading = false;
                })
            },
            // 推荐活动、活动详情
            handleClick(obj) {
                this.$refs.articleRef.show({
                    imgUrl: `${this.cxdjcs}${obj.img}`,
                    title: obj.title,
                    content: obj.content,
                    createtime: obj.publishTime,
                })
            },
            activeLoad() {
                this.loading = true
                setTimeout(() => {
                    this.activityQuery.pageNum += 1; //滚动条到底时，页码自增 1
                    this.getActivityList(); //调用接口
                }, 500)
            },
            cellStyle() {
                return "cursor:pointer;"
            },
        }
    }
</script>
<style lang='scss' scoped>
    .cxdj {
        display: flex;
        flex-direction: column;

        .top {
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;

            .info-item {
                display: flex;
                align-items: center;

                >span:first-child {
                    color: #fff;
                    font-size: 22px;
                    margin-right: 25px;
                }

                .text-wrapper {
                    width: 34px;
                    height: 46px;
                    text-align: center;
                    line-height: 46px;
                    font-size: 32px;
                    background: url("./num-bg.png") no-repeat center/cover;
                    margin-right: 4px;

                    .num {
                        background-image: linear-gradient(0deg, #FED471 0%, #FDF79E 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }

                >span:last-child {
                    color: #fff;
                    font-size: 18px;
                    margin-left: 15px;
                    margin-right: 140px;
                }
            }
        }

        .bottom {
            flex: 1;
            display: flex;
            margin: 0 70px;

            .left {
                width: 690px;
                height: 740px;
                background: transparent;

                .list {
                    height: 690px;
                    padding: 20px 30px;
                    box-sizing: border-box;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 0px;
                        /* 设置滚动条的宽度 */
                    }

                    li {
                        width: 610px;
                        height: 125px;
                        padding: 20px 0;
                        border-bottom: 1px dashed #7AAFFF;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        overflow: hidden;
                        cursor: pointer;

                        img {
                            width: 159px;
                            height: 126px;
                        }

                        >div {
                            width: 430px;
                            height: 105px;
                            overflow: hidden;
                            display: -webkit-box; //将元素设为盒子伸缩模型显示
                            -webkit-box-orient: vertical; //伸缩方向设为垂直方向
                            -webkit-line-clamp: 6; //超出3行隐藏，并显示省略号

                            p {
                                height: 105px;
                                overflow: hidden;
                                display: -webkit-box; //将元素设为盒子伸缩模型显示
                                -webkit-box-orient: vertical; //伸缩方向设为垂直方向
                                -webkit-line-clamp: 6; //超出3行隐藏，并显示省略号
                            }

                            /* 添加自定义滚动条样式 */
                            scrollbar-width: thin;
                            /* 设置滚动条的宽度 */
                            scrollbar-color: #999999 #f0f0f0;

                            /* 设置滚动条的颜色 */
                            &::-webkit-scrollbar {
                                width: 5px;
                                /* 设置滚动条的宽度 */
                            }

                            &::-webkit-scrollbar-track {
                                background-color: #021033;
                                /* 设置滚动条背景色 */
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: #00abff;
                                /* 设置滚动条的颜色 */
                            }
                        }
                    }

                    >p {
                        text-align: center;
                        margin-top: 10px;
                    }
                }
            }

            .right {
                flex: 1;
                margin-left: 50px;

                .operator {
                    display: flex;
                    justify-content: center;
                    margin: 16px 0;

                    ::v-deep .el-input {
                        margin-right: 17px;
                        width: 214px;
                    }

                    .btn {
                        width: 53px;
                        height: 40px;
                        background: #1890FF;
                        line-height: 40px;
                        text-align: center;
                        color: #fff;
                        font-size: 22px;
                        cursor: pointer;
                    }
                }

                ::v-deep .el-table .el-table__body tbody tr,
                ::v-deep .el-table__header tr th {
                    height: 55px;
                }

            }
        }

        .itemDetail {
            padding: 30px 50px;

            li {
                line-height: 35px;
                font-size: 20px;
            }
        }
    }
</style>
